<template>
  <div class="container-fluid" id="order-details">
    <div class="row">
      <div class="col-lg-12">
        <div
          class="d-flex flex-wrap align-items-center justify-content-between mb-3"
        >
          <div class="d-flex align-items-center justify-content-between">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb p-0 mb-0">
                <li class="breadcrumb-item">
                  <router-link :to="{ name: 'layout.order' }"
                    >Orders</router-link
                  >
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Order Details
                </li>
              </ol>
            </nav>
          </div>
          <div class="d-flex">            
            <div style="padding-right: 10px">
              <button
                class="btn btn-secondary btn-sm ml-2"
                style="cursor: pointer; font-size: 16px"
                @click="handleSendToTrello"
              >
              <svg xmlns="http://www.w3.org/2000/svg" fill="#fff" height="16" width="16">
                <rect fill="#0079bf" height="16" rx="15%" width="16"/>
                <rect height="5.88" rx="0.72" width="4.125" x="8.88" y="2.97"/>
                <rect height="9.25" rx="0.72" width="4.125" x="3.03" y="2.97"/>
              </svg>
                Send to Trello
              </button>
            </div>
            <div>
              <button
                class="btn btn-primary btn-sm"
                @click="handleUploadQuotation"
                style="font-size: 16px"
              >
                <svg
                  width="24"
                  height="24"
                  xmlns="http://www.w3.org/2000/svg"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  fill="white"
                  style="margin-right: 10px"
                >
                  <path
                    d="M11.492 10.172l-2.5 3.064-.737-.677 3.737-4.559 3.753 4.585-.753.665-2.5-3.076v7.826h-1v-7.828zm7.008 9.828h-13c-2.481 0-4.5-2.018-4.5-4.5 0-2.178 1.555-4.038 3.698-4.424l.779-.14.043-.789c.185-3.448 3.031-6.147 6.48-6.147 3.449 0 6.295 2.699 6.478 6.147l.044.789.78.14c2.142.386 3.698 2.246 3.698 4.424 0 2.482-2.019 4.5-4.5 4.5m.978-9.908c-.212-3.951-3.472-7.092-7.478-7.092s-7.267 3.141-7.479 7.092c-2.57.463-4.521 2.706-4.521 5.408 0 3.037 2.463 5.5 5.5 5.5h13c3.037 0 5.5-2.463 5.5-5.5 0-2.702-1.951-4.945-4.522-5.408"
                  />
                </svg>
                Upload
              </button>
            </div>

            <div 
            v-if="order.workshopAssignedAt && !order.workshopAcceptedBy">
              <button
                class="btn btn-success order-add btn-sm ml-2"
                style="cursor: pointer"
                @click="handleAccept"
              >
                <svg
                  fill="#ffffff"
                  width="16"
                  height="16"
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 512 512"
                  enable-background="new 0 0 512 512"
                  xml:space="preserve"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <polygon
                      points="437.3,30 202.7,339.3 64,200.7 0,264.7 213.3,478 512,94 "
                    ></polygon>
                  </g>
                </svg>
                Accept
              </button>
            </div>
            <div v-if="order.workshopAssignedAt && !order.workshopAcceptedBy">
              <button
                class="btn btn-danger order-add btn-sm ml-2"
                style="cursor: pointer"
                @click="handleReject"
              >
                <svg
                  fill="#ffffff"
                  version="1.1"
                  width="16"
                  height="16"
                  baseProfile="tiny"
                  id="Layer_1"
                  xmlns:x="&amp;ns_extend;"
                  xmlns:i="&amp;ns_ai;"
                  xmlns:graph="&amp;ns_graphs;"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/"
                  viewBox="0 0 42 42"
                  xml:space="preserve"
                  stroke="#ffffff"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <path
                      fill-rule="evenodd"
                      d="M21.002,26.588l10.357,10.604c1.039,1.072,1.715,1.083,2.773,0l2.078-2.128 c1.018-1.042,1.087-1.726,0-2.839L25.245,21L36.211,9.775c1.027-1.055,1.047-1.767,0-2.84l-2.078-2.127 c-1.078-1.104-1.744-1.053-2.773,0L21.002,15.412L10.645,4.809c-1.029-1.053-1.695-1.104-2.773,0L5.794,6.936 c-1.048,1.073-1.029,1.785,0,2.84L16.759,21L5.794,32.225c-1.087,1.113-1.029,1.797,0,2.839l2.077,2.128 c1.049,1.083,1.725,1.072,2.773,0L21.002,26.588z"
                    ></path>
                  </g>
                </svg>
                Reject
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="main-div">
      <div class="row">
        <div class="col-md-4">
          <div class="card">
            <ul class="list-group list-group-flush">
              <li class="list-group-item p-3">
                <h5 class="font-weight-bold pb-2">Order Info</h5>
                <div class="table-responsive">
                  <table class="table table-borderless mb-0">
                    <tbody v-if="order">
                      <tr class="white-space-no-wrap">
                        <td class="text-muted pl-0">Number</td>
                        <td>
                          {{ this.orderId }}
                          <span
                            @click.prevent="copyIdToClipboard(orderId)"
                            class="ml-1 cursor-pointer"
                          >
                            <svg
                              v-b-tooltip.top
                              title="Click to Copy"
                              fill="#158df7cc"
                              width="20"
                              height="20"
                              clip-rule="evenodd"
                              fill-rule="evenodd"
                              stroke-linejoin="round"
                              stroke-miterlimit="2"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="m6 18h-3c-.48 0-1-.379-1-1v-14c0-.481.38-1 1-1h14c.621 0 1 .522 1 1v3h3c.621 0 1 .522 1 1v14c0 .621-.522 1-1 1h-14c-.48 0-1-.379-1-1zm1.5-10.5v13h13v-13zm9-1.5v-2.5h-13v13h2.5v-9.5c0-.481.38-1 1-1z"
                                fill-rule="nonzero"
                              />
                            </svg>
                          </span>
                        </td>
                      </tr>
                      <tr class="white-space-no-wrap">
                        <td class="text-muted pl-0">Status</td>
                        <td>{{ order.status }}</td>
                      </tr>
                      <tr class="white-space-no-wrap">
                        <td class="text-muted pl-0">Assigned To</td>
                        <td>
                          {{
                            order.assignedTo
                              ? order.assignedTo.firstName +
                                " " +
                                order.assignedTo.lastName
                              : "-"
                          }}
                        </td>
                      </tr>
                      <tr class="white-space-no-wrap">
                        <td class="text-muted pl-0">Accepted By</td>
                        <td>
                          {{
                            order.workshopAcceptedBy
                              ? order.workshopAcceptedBy.firstName +
                                " " +
                                order.workshopAcceptedBy.lastName
                              : "-"
                          }}
                        </td>
                      </tr>
                      <tr class="white-space-no-wrap">
                        <td class="text-muted pl-0">Scheduled At</td>
                        <td :title="order.scheduledAt">
                          {{ order.scheduledAt | DateTimeFormat }}
                        </td>
                      </tr>
                      <tr class="white-space-no-wrap">
                        <td class="text-muted pl-0">Expected Completion</td>
                        <td
                          v-if="order.workshopExpectedCompletionAt"
                          :title="order.workshopExpectedCompletionAt"
                        >
                          {{
                            order.workshopExpectedCompletionAt | DateTimeFormat
                          }}
                        </td>
                        <td v-else>-</td>
                      </tr>
                      <tr class="white-space-no-wrap">
                        <td class="text-muted pl-0">Workshop Tracking PDF</td>
                        <td>
                          <button
                            class="btn btn-primary btn-sm mr-1"
                            @click="handleTrackingPdf"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="white"
                              width="15"
                              height="15"
                              viewBox="0 0 24 24"
                            >
                              <path
                                d="M6 17c2.269-9.881 11-11.667 11-11.667v-3.333l7 6.637-7 6.696v-3.333s-6.17-.171-11 5zm12 .145v2.855h-16v-12h6.598c.768-.787 1.561-1.449 2.339-2h-10.937v16h20v-6.769l-2 1.914z"
                              />
                            </svg>
                            Open
                          </button>
                        </td>
                      </tr>
                      <tr class="white-space-no-wrap">
                        <td class="text-muted pl-0">Quoted At</td>
                        <td
                          v-if="order.workshopQuotedAt"
                          :title="order.workshopQuotedAt"
                        >
                          {{ order.workshopQuotedAt | DateTimeFormat }}
                        </td>
                        <td v-else>-</td>
                      </tr>
                      <tr class="white-space-no-wrap">
                        <td class="text-muted pl-0">Elapsed Days</td>
                        <td>
                          {{
                            new Date(order.scheduledAt).getTime() >
                            new Date().getTime()
                              ? 0
                              : Math.ceil(
                                  (new Date().getTime() -
                                    new Date(order.scheduledAt).getTime()) /
                                    (1000 * 3600 * 24) -
                                    1
                                )
                          }}
                        </td>
                      </tr>
                      <tr class="white-space-no-wrap">
                        <td class="text-muted pl-0">Quote Required</td>
                        <td
                          class="table-font"
                          v-if="order.isQuoteRequired === false"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="red"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                          >
                            <path
                              d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z"
                            />
                          </svg>
                        </td>
                        <td v-else>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="green"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                          >
                            <path
                              d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.25 17.292l-4.5-4.364 1.857-1.858 2.643 2.506 5.643-5.784 1.857 1.857-7.5 7.643z"
                            />
                          </svg>
                        </td>
                      </tr>

                      <tr>
                        <td class="text-muted pl-0 white-space-no-wrap">
                          Approved Quotation
                        </td>
                        <td class="table-font" v-if="order.approvedQuotation">
                          <a
                            class="mr-2"
                            :href="order.approvedQuotation"
                            target="_blank"
                            >{{ approvedQuotationName }}
                          </a>
                        </td>
                        <td v-else>-</td>
                      </tr>
                      <tr class="white-space-no-wrap">
                        <td class="text-muted pl-0">Is Fully Quoted</td>
                        <td
                          class="table-font"
                          v-if="order.isFullyQuoted === false"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="red"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                          >
                            <path
                              d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z"
                            />
                          </svg>
                        </td>
                        <td v-else-if="order.isFullyQuoted === true">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="green"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                          >
                            <path
                              d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.25 17.292l-4.5-4.364 1.857-1.858 2.643 2.506 5.643-5.784 1.857 1.857-7.5 7.643z"
                            />
                          </svg>
                        </td>
                        <td v-else>
                          -
                        </td>
                      </tr>  
                    </tbody>
                  </table>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div class="col-md-8">
          <div class="row">
            <div class="col-md-4">
              <div class="card">
                <ul class="list-group list-group-flush">
                  <li class="list-group-item p-3">
                    <h5 class="font-weight-bold pb-2">Car Info</h5>
                    <div class="table-responsive">
                      <table class="table table-borderless mb-0">
                        <tbody v-if="order.car">
                          <tr class="white-space-no-wrap">
                            <td class="text-muted pl-0">Car</td>
                            <td>
                              {{
                                order.car.model.make.name +
                                " " +
                                order.car.model.name
                              }}
                            </td>
                          </tr>
                          <tr class="white-space-no-wrap">
                            <td class="text-muted pl-0">Year</td>
                            <td>{{ order.car.year }}</td>
                          </tr>
                          <tr class="white-space-no-wrap">
                            <td class="text-muted pl-0">Plate No</td>
                            <td>{{ order.car.licensePlate || " - " }}</td>
                          </tr>
                          <tr class="white-space-no-wrap" v-if="order.car.vin">
                            <td class="text-muted pl-0">VIN</td>
                            <td>{{ order.car.vin }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-md-3">
              <div class="card">
                <ul class="list-group list-group-flush">
                  <li class="list-group-item p-3">
                    <h5 class="font-weight-bold">Services</h5>
                  </li>
                  <li class="list-group-item p-0">
                    <div class="table-responsive">
                      <table class="table mb-0">
                        <tbody>
                          <tr v-for="(item, index) in order.lines" :key="index">
                            <td>
                              {{ item.serviceName }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-md-5">
              <div class="card attachement-card">
                <div>
                  <h5 class="font-weight-bold" style="padding:20px">Attachments</h5>
                  <div
                    class="divScroll"
                    v-if="
                      order.attachments && order.attachments.length > 0
                    "
                  >
                    <div
                      v-for="(file, index) in order.attachments"
                      v-bind:key="index"
                    >
                      <div
                        style="
                          display: flex;
                          align-items: center;
                          padding: 7px 15px;
                        "
                      >
                        <div style="display: block">
                          <a
                            class="mr-2"
                            :href="file.file"
                            target="_blank"
                          >
                            <img
                              v-if="
                                file.extension == 'png' ||
                                file.extension == 'jpeg' ||
                                file.extension == 'jpg'
                              "
                              :src="file.file"
                              class="img-fluid rounded-normal"
                              width="140px"
                              alt="logo"
                            />
                            <svg
                              v-else-if="file.extension == 'pdf'"
                              height="80"
                              width="100"
                              version="1.1"
                              id="Capa_1"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                              viewBox="0 0 56 56"
                              xml:space="preserve"
                            >
                              <g>
                                <path
                                  style="fill: #e9e9e0"
                                  d="M36.985,0H7.963C7.155,0,6.5,0.655,6.5,1.926V55c0,0.345,0.655,1,1.463,1h40.074
c0.808,0,1.463-0.655,1.463-1V12.978c0-0.696-0.093-0.92-0.257-1.085L37.607,0.257C37.442,0.093,37.218,0,36.985,0z"
                                />
                                <polygon
                                  style="fill: #d9d7ca"
                                  points="37.5,0.151 37.5,12 49.349,12 	"
                                />
                                <path
                                  style="fill: #cc4b4c"
                                  d="M19.514,33.324L19.514,33.324c-0.348,0-0.682-0.113-0.967-0.326
c-1.041-0.781-1.181-1.65-1.115-2.242c0.182-1.628,2.195-3.332,5.985-5.068c1.504-3.296,2.935-7.357,3.788-10.75
c-0.998-2.172-1.968-4.99-1.261-6.643c0.248-0.579,0.557-1.023,1.134-1.215c0.228-0.076,0.804-0.172,1.016-0.172
c0.504,0,0.947,0.649,1.261,1.049c0.295,0.376,0.964,1.173-0.373,6.802c1.348,2.784,3.258,5.62,5.088,7.562
c1.311-0.237,2.439-0.358,3.358-0.358c1.566,0,2.515,0.365,2.902,1.117c0.32,0.622,0.189,1.349-0.39,2.16
c-0.557,0.779-1.325,1.191-2.22,1.191c-1.216,0-2.632-0.768-4.211-2.285c-2.837,0.593-6.15,1.651-8.828,2.822
c-0.836,1.774-1.637,3.203-2.383,4.251C21.273,32.654,20.389,33.324,19.514,33.324z M22.176,28.198
c-2.137,1.201-3.008,2.188-3.071,2.744c-0.01,0.092-0.037,0.334,0.431,0.692C19.685,31.587,20.555,31.19,22.176,28.198z
M35.813,23.756c0.815,0.627,1.014,0.944,1.547,0.944c0.234,0,0.901-0.01,1.21-0.441c0.149-0.209,0.207-0.343,0.23-0.415
c-0.123-0.065-0.286-0.197-1.175-0.197C37.12,23.648,36.485,23.67,35.813,23.756z M28.343,17.174
c-0.715,2.474-1.659,5.145-2.674,7.564c2.09-0.811,4.362-1.519,6.496-2.02C30.815,21.15,29.466,19.192,28.343,17.174z
M27.736,8.712c-0.098,0.033-1.33,1.757,0.096,3.216C28.781,9.813,27.779,8.698,27.736,8.712z"
                                />
                                <path
                                  style="fill: #cc4b4c"
                                  d="M48.037,56H7.963C7.155,56,6.5,55.345,6.5,54.537V39h43v15.537C49.5,55.345,48.845,56,48.037,56z"
                                />
                                <g>
                                  <path
                                    style="fill: #ffffff"
                                    d="M17.385,53h-1.641V42.924h2.898c0.428,0,0.852,0.068,1.271,0.205
  c0.419,0.137,0.795,0.342,1.128,0.615c0.333,0.273,0.602,0.604,0.807,0.991s0.308,0.822,0.308,1.306
  c0,0.511-0.087,0.973-0.26,1.388c-0.173,0.415-0.415,0.764-0.725,1.046c-0.31,0.282-0.684,0.501-1.121,0.656
  s-0.921,0.232-1.449,0.232h-1.217V53z M17.385,44.168v3.992h1.504c0.2,0,0.398-0.034,0.595-0.103
  c0.196-0.068,0.376-0.18,0.54-0.335c0.164-0.155,0.296-0.371,0.396-0.649c0.1-0.278,0.15-0.622,0.15-1.032
  c0-0.164-0.023-0.354-0.068-0.567c-0.046-0.214-0.139-0.419-0.28-0.615c-0.142-0.196-0.34-0.36-0.595-0.492
  c-0.255-0.132-0.593-0.198-1.012-0.198H17.385z"
                                  />
                                  <path
                                    style="fill: #ffffff"
                                    d="M32.219,47.682c0,0.829-0.089,1.538-0.267,2.126s-0.403,1.08-0.677,1.477s-0.581,0.709-0.923,0.937
  s-0.672,0.398-0.991,0.513c-0.319,0.114-0.611,0.187-0.875,0.219C28.222,52.984,28.026,53,27.898,53h-3.814V42.924h3.035
  c0.848,0,1.593,0.135,2.235,0.403s1.176,0.627,1.6,1.073s0.74,0.955,0.95,1.524C32.114,46.494,32.219,47.08,32.219,47.682z
  M27.352,51.797c1.112,0,1.914-0.355,2.406-1.066s0.738-1.741,0.738-3.09c0-0.419-0.05-0.834-0.15-1.244
  c-0.101-0.41-0.294-0.781-0.581-1.114s-0.677-0.602-1.169-0.807s-1.13-0.308-1.914-0.308h-0.957v7.629H27.352z"
                                  />
                                  <path
                                    style="fill: #ffffff"
                                    d="M36.266,44.168v3.172h4.211v1.121h-4.211V53h-1.668V42.924H40.9v1.244H36.266z"
                                  />
                                </g>
                              </g>
                            </svg>
                            <svg
                              v-else-if="file.extension == 'xlsx'"
                              height="80"
                              width="100"
                              viewBox="0 0 32 32"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                              fill="#000000"
                            >
                              <g
                                id="SVGRepo_bgCarrier"
                                stroke-width="0"
                              ></g>
                              <g
                                id="SVGRepo_tracerCarrier"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></g>
                              <g id="SVGRepo_iconCarrier">
                                <defs>
                                  <linearGradient
                                    id="a"
                                    x1="4.494"
                                    y1="-2092.086"
                                    x2="13.832"
                                    y2="-2075.914"
                                    gradientTransform="translate(0 2100)"
                                    gradientUnits="userSpaceOnUse"
                                  >
                                    <stop
                                      offset="0"
                                      stop-color="#18884f"
                                    ></stop>
                                    <stop
                                      offset="0.5"
                                      stop-color="#117e43"
                                    ></stop>
                                    <stop
                                      offset="1"
                                      stop-color="#0b6631"
                                    ></stop>
                                  </linearGradient>
                                </defs>
                                <title>file_type_excel</title>
                                <path
                                  d="M19.581,15.35,8.512,13.4V27.809A1.192,1.192,0,0,0,9.705,29h19.1A1.192,1.192,0,0,0,30,27.809h0V22.5Z"
                                  style="fill: #185c37"
                                ></path>
                                <path
                                  d="M19.581,3H9.705A1.192,1.192,0,0,0,8.512,4.191h0V9.5L19.581,16l5.861,1.95L30,16V9.5Z"
                                  style="fill: #21a366"
                                ></path>
                                <path
                                  d="M8.512,9.5H19.581V16H8.512Z"
                                  style="fill: #107c41"
                                ></path>
                                <path
                                  d="M16.434,8.2H8.512V24.45h7.922a1.2,1.2,0,0,0,1.194-1.191V9.391A1.2,1.2,0,0,0,16.434,8.2Z"
                                  style="
                                    opacity: 0.10000000149011612;
                                    isolation: isolate;
                                  "
                                ></path>
                                <path
                                  d="M15.783,8.85H8.512V25.1h7.271a1.2,1.2,0,0,0,1.194-1.191V10.041A1.2,1.2,0,0,0,15.783,8.85Z"
                                  style="
                                    opacity: 0.20000000298023224;
                                    isolation: isolate;
                                  "
                                ></path>
                                <path
                                  d="M15.783,8.85H8.512V23.8h7.271a1.2,1.2,0,0,0,1.194-1.191V10.041A1.2,1.2,0,0,0,15.783,8.85Z"
                                  style="
                                    opacity: 0.20000000298023224;
                                    isolation: isolate;
                                  "
                                ></path>
                                <path
                                  d="M15.132,8.85H8.512V23.8h6.62a1.2,1.2,0,0,0,1.194-1.191V10.041A1.2,1.2,0,0,0,15.132,8.85Z"
                                  style="
                                    opacity: 0.20000000298023224;
                                    isolation: isolate;
                                  "
                                ></path>
                                <path
                                  d="M3.194,8.85H15.132a1.193,1.193,0,0,1,1.194,1.191V21.959a1.193,1.193,0,0,1-1.194,1.191H3.194A1.192,1.192,0,0,1,2,21.959V10.041A1.192,1.192,0,0,1,3.194,8.85Z"
                                  style="fill: url(#a)"
                                ></path>
                                <path
                                  d="M5.7,19.873l2.511-3.884-2.3-3.862H7.758L9.013,14.6c.116.234.2.408.238.524h.017c.082-.188.169-.369.26-.546l1.342-2.447h1.7l-2.359,3.84,2.419,3.905H10.821l-1.45-2.711A2.355,2.355,0,0,1,9.2,16.8H9.176a1.688,1.688,0,0,1-.168.351L7.515,19.873Z"
                                  style="fill: #fff"
                                ></path>
                                <path
                                  d="M28.806,3H19.581V9.5H30V4.191A1.192,1.192,0,0,0,28.806,3Z"
                                  style="fill: #33c481"
                                ></path>
                                <path
                                  d="M19.581,16H30v6.5H19.581Z"
                                  style="fill: #107c41"
                                ></path>
                              </g>
                            </svg>
                            <svg
                              v-else
                              fill="#0D5FFF"
                              height="80"
                              width="100"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                            >
                              <path
                                d="M22 24h-20v-24h14l6 6v18zm-7-23h-12v22h18v-16h-6v-6zm3 15v1h-12v-1h12zm0-3v1h-12v-1h12zm0-3v1h-12v-1h12zm-2-4h4.586l-4.586-4.586v4.586z"
                              />
                            </svg>
                          </a>
                          <div style="font-size: 12px">
                            By : {{ file.uploadedBy.firstName }}
                            <br />
                            {{ file.uploadedAt | DateTimeFormat }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else class="p-3" style="text-align: center">
                    No Images
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-7">
              <div class="card">
                <ul class="list-group list-group-flush">
                  <li class="list-group-item p-3">
                    <h5 class="font-weight-bold">Notes</h5>
                  </li>
                  <li class="list-group-item p-3">
                    <div class="table-responsive">
                      <table class="table table-borderless mb-0">
                        <tbody>
                          <tr>
                            <td class="text-muted pl-0">Staff Note</td>
                            <td id="notes" class="d-flex">
                              <span
                                v-if="order.staffNote"
                                @click.prevent="
                                  copyStaffNoteToClipboard(order.staffNote)
                                "
                                class="ml-1 cursor-pointer"
                              >
                                <svg
                                  v-b-tooltip.top
                                  title="Click to Copy"
                                  fill="#158df7cc"
                                  width="20"
                                  height="20"
                                  clip-rule="evenodd"
                                  fill-rule="evenodd"
                                  stroke-linejoin="round"
                                  stroke-miterlimit="2"
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="m6 18h-3c-.48 0-1-.379-1-1v-14c0-.481.38-1 1-1h14c.621 0 1 .522 1 1v3h3c.621 0 1 .522 1 1v14c0 .621-.522 1-1 1h-14c-.48 0-1-.379-1-1zm1.5-10.5v13h13v-13zm9-1.5v-2.5h-13v13h2.5v-9.5c0-.481.38-1 1-1z"
                                    fill-rule="nonzero"
                                  />
                                </svg>
                              </span>
                              <span class="ml-1 notes"
                                >{{
                                  order.staffNote == ""
                                    ? " - "
                                    : order.staffNote
                                }}
                              </span>
                            </td>
                          </tr>

                          <tr>
                            <td class="text-muted pl-0">Valet Note</td>
                            <td>
                              <span
                                v-if="order.valetNote"
                                @click.prevent="
                                  copyValteNoteToClipboard(order.valetNote)
                                "
                                class="ml-1 cursor-pointer"
                              >
                                <svg
                                  v-b-tooltip.top
                                  title="Click to Copy"
                                  fill="#158df7cc"
                                  width="20"
                                  height="20"
                                  clip-rule="evenodd"
                                  fill-rule="evenodd"
                                  stroke-linejoin="round"
                                  stroke-miterlimit="2"
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="m6 18h-3c-.48 0-1-.379-1-1v-14c0-.481.38-1 1-1h14c.621 0 1 .522 1 1v3h3c.621 0 1 .522 1 1v14c0 .621-.522 1-1 1h-14c-.48 0-1-.379-1-1zm1.5-10.5v13h13v-13zm9-1.5v-2.5h-13v13h2.5v-9.5c0-.481.38-1 1-1z"
                                    fill-rule="nonzero"
                                  />
                                </svg>
                              </span>
                              <span class="ml-1 notes"
                                >{{
                                  order.valetNote == ""
                                    ? " - "
                                    : order.valetNote
                                }}
                              </span>
                            </td>
                          </tr>
                          <tr
                            class="white-space-no-wrap"
                            v-if="order.cancelReason"
                          >
                            <td class="text-muted pl-0">
                              Customer Cancel Reason
                            </td>
                            <td>
                              {{ order.cancelReason }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-md-5">
              <div class="card">
                <ul class="list-group list-group-flush">
                  <li class="list-group-item p-3">
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <h5 class="font-weight-bold pb-2">Comments</h5>

                      <div
                        class="btn btn-success btn-sm position-relative d-flex align-items-center justify-content-between"
                        title="Add Comment"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          @click="handleComment()"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                          />
                        </svg>
                      </div>
                    </div>
                    <div
                      class="table-responsive commentScroll"
                      v-if="order.comments && order.comments.length > 0"
                    >
                      <div v-for="(item, index) in order.comments" :key="index">
                        <div>
                          <span
                            style="
                              font-size: 16px;
                              font-weight: bold;
                              color: black;
                            "
                            >{{ item.createdBy.firstName }}</span
                          >
                          -
                          <span style="font-size: 12px">{{
                            item.createdAt | DateTimeFormat
                          }}</span>
                        </div>
                        <div class="comments" style="font-size: 16px; margin-bottom: 10px">{{ item.comment }}</div>
                      </div>
                    </div>
                    <div v-else class="p-3" style="text-align: center">
                      No Comments
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-md-12">
              <div class="card">
              <div
                class="d-flex flex-wrap justify-content-between mb-2 p-3"
              >
                <h5 class="font-weight-bold">Workshop Service Jobs</h5>
                <div>
                  <div
                    class="btn btn-success btn-sm mr-2"
                    @click="handleAddWorkshopJob"
                    id="fulfill-order"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
                    </svg>
                    Add Job
                  </div>
                </div>
              </div>
              <div class="table-responsive">
                <table
                  class="table mb-0 text-center"
                  id="job-info-table"
                >
                  <thead>
                    <tr class="text-muted">
                      <th scope="col"></th>
                      <th scope="col">Status</th>
                      <th scope="col">Schedule</th>
                      <th scope="col">Assigned To</th>
                      <th scope="col">Hours Rqd.<br>Taken</th>
                      <th scope="col">Notes</th>
                    </tr>
                  </thead>
                  <tbody id="job-info-table-body">
                    <tr
                      class="transition-row"
                      v-for="(wjob, index) in order.workshopJobs"
                      :key="index"
                    >
                      <td style="padding-right: 0 !important;">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="text-secondary mx-1 job-info-svg"
                          width="20"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          @click="handleUpdateJobInfo(wjob, 'workshop')"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                          />
                        </svg>
                      </td>
                      <td>{{ wjob.status }}</td>
                      <td style="white-space: pre">
                        <span :title="wjob.scheduledAt">{{ wjob.scheduledAt|DateTimeFormat }}</span>
                        <br>
                        <span :title="wjob.scheduledUpto">{{ wjob.scheduledUpto|DateTimeFormat }}</span>
                      </td>
                      <td v-if="wjob.assignedTo">
                        {{
                          wjob.assignedTo.firstName +
                          " " +
                          wjob.assignedTo.lastName
                        }}
                      </td>
                      <td v-else>-</td>
                      <td>
                        <span title="Hours required">{{ wjob.hoursRequired || "-" }}</span>
                        <br><span title="Hours taken">{{ wjob.hoursTaken || "-" }}</span>
                      </td>
                      <td>{{ wjob.notes || "-" }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            </div>

            <div class="col-md-12">
              <div class="card">
              <div
                class="d-flex flex-wrap justify-content-between mb-2 p-3"
              >
                <h5 class="font-weight-bold">Parts Jobs</h5>
                <div>
                  <div
                    class="btn btn-success btn-sm mr-2"
                    @click="handleAddPartsJob"
                    id="fulfill-order"
                    v-if="!order.partsJob"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
                    </svg>
                    Add Job
                  </div>
                </div>
              </div>
              <div class="table-responsive">
                <table
                  class="table mb-0 text-center"
                  id="job-info-table"
                >
                  <thead>
                    <tr class="text-muted">
                      <th scope="col"></th>
                      <th scope="col">Status</th>
                      <th scope="col">Schedule</th>
                      <th scope="col">Assigned To</th>
                      <th scope="col">Notes</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody id="job-info-table-body">
                    <tr class="transition-row" v-if="order.partsJob">
                      <td style="padding-right: 0 !important;">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="text-secondary mx-1 job-info-svg"
                          width="20"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          @click="handleUpdateJobInfo(order.partsJob, 'parts')"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                          />
                        </svg>
                      </td>
                      <td>{{ order.partsJob.status }}</td>
                      <td style="white-space: pre" :title="order.partsJob.scheduledAt">
                        {{ order.partsJob.scheduledAt|DateTimeFormat }}
                      </td>
                      <td v-if="order.partsJob.assignedTo">
                        {{
                          order.partsJob.assignedTo.firstName +
                          " " +
                          order.partsJob.assignedTo.lastName
                        }}
                      </td>
                      <td v-else>-</td>
                      <td>{{ order.partsJob.notes || "-" }}</td>
                      <td class="table-font">
                        <button
                          class="btn btn-info btn-sm mr-2"
                          v-for="pAction in order.partsJob.possibleActions"
                          v-bind:key="pAction"
                          v-bind:pAction="pAction"
                          @click="partsJobConfirmAction(pAction)"
                        > Mark as {{ pAction }} </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-9">
          <div class="card">
            <ul class="list-group list-group-flush">
              <li class="list-group-item p-3 d-flex justify-content-between">
                <h5 class="font-weight-bold">Part Quotations</h5>
                <div class="d-flex">
                  <div class="d-flex">
                      <label
                        for="car-make"
                        class="
                          form-label
                          text-muted
                          filter-label-heading
                          white-space
                          mt-1
                          mr-2
                        "
                        >Show Deleted
                      </label>
                      <b-form-checkbox
                        id="is-quoted"
                        size="lg"
                        v-model="checkedShowExpired"
                        @input="handleShowExpired"
                        name="check-button"
                        switch
                        style="margin-right: 10px"
                        :class="{'borderColorFilter': showExpired, 'is-true':true}"
                      >
                      </b-form-checkbox>
                  </div>
                  <button
                    class="btn btn-warning btn-sm mr-2"
                    @click="copyAllPartQuotationToClipboard"
                    style="font-size: 16px"
                  >
                  <svg
                    v-b-tooltip.top
                    title="Click to Copy"
                    fill="#fff"
                    width="20"
                    height="20"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                    stroke-linejoin="round"
                    stroke-miterlimit="2"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="m6 18h-3c-.48 0-1-.379-1-1v-14c0-.481.38-1 1-1h14c.621 0 1 .522 1 1v3h3c.621 0 1 .522 1 1v14c0 .621-.522 1-1 1h-14c-.48 0-1-.379-1-1zm1.5-10.5v13h13v-13zm9-1.5v-2.5h-13v13h2.5v-9.5c0-.481.38-1 1-1z"
                      fill-rule="nonzero"
                    />
                  </svg>
                    Copy All
                  </button>
                  <button
                    class="btn btn-primary btn-sm"
                    @click="addPartQuotation"
                    style="font-size: 16px"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
                    </svg>
                    Add Quote
                  </button>
                </div>
              </li>
              <div class="">
                <div class="p-2">
                  <div
                    style="max-height: 400px"
                    class="table-responsive table-zoom"
                    v-if="
                      partQuotations && partQuotations.length > 0
                    "
                  >
                    <table class="table data-table mb-0 table-customer">
                      <thead class="table-color-heading">
                        <tr style="text-align: center">
                          <th scope="col">Part Name</th>
                          <th scope="col">Unit Price</th>
                          <th scope="col">Quantity</th>
                          <th scope="col">Price</th>
                          <th scope="col">Quality</th>
                          <th scope="col">Availability</th>
                          <th scope="col">Status</th>
                          <th scope="col">Brand</th>
                          <th scope="col">Part No.</th>
                          <th scope="col">Created By</th>
                          <th scope="col">Created At</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          class="white-space-no-wrap transition-row"
                          v-for="pq in partQuotations"
                          :key="pq.id"
                        >
                          <td class="table-font" style="text-align: center">
                            {{ pq.partName }}
                          </td>
                          <td class="table-font" style="text-align: right">
                            {{
                              pq.unitPriceSupplier.currency +
                              " " +
                              pq.unitPriceSupplier.amount.toFixed(2).toLocaleString("en-US")
                            }}
                          </td>
                          <td class="table-font" style="text-align: center">
                            {{ pq.quantity }}
                          </td>
                          <td class="table-font" style="text-align: right">
                            {{
                              pq.priceSupplier.currency +
                              " " +
                              pq.priceSupplier.amount.toFixed(2).toLocaleString("en-US")
                            }}
                          </td>
                          <td class="table-font" style="text-align: center">
                            {{ pq.quality | customTitleCase }}
                          </td>
                          <td class="table-font" style="text-align: center">
                            {{ pq.availability }}
                                                        
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="text-secondary mx-1 job-info-svg"
                              width="20"
                              height="20"
                              fill="blue"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              @click.prevent="editPartQuotation(pq.id)"
                              title="Edit"
                              v-if="
                                pq.availability == 'Unavailable' && pq.status !== 'EXPIRED'
                              "
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                              />
                            </svg>
                          </td>
                          <td class="table-font" style="text-align: center">
                            {{ pq.status | customTitleCase }}
                          </td>
                          <td class="table-font" style="text-align: center">
                            {{ pq.brand ? pq.brand : "-" }}
                          </td>
                          <td class="table-font" style="text-align: center">
                            {{ pq.partNumber ? pq.partNumber : "-" }}
                          </td>
                          <td class="table-font" style="text-align: center">
                            {{
                              pq.createdBy.firstName +
                              " " +
                              pq.createdBy.lastName
                            }}
                          </td>
                          <td class="table-font" style="text-align: center">
                            {{ pq.createdAt | DateTimeFormat }}
                          </td>
                          <td class="table-font" style="text-align: center" v-if="pq.status !== 'EXPIRED'">                     
                            <svg
                              @click="showDeletePartQuotationModal(pq.id)"
                              style="cursor: pointer"
                              viewBox="0 0 24 24"
                              fill="none"
                              width="20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                              <g
                                id="SVGRepo_tracerCarrier"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></g>
                              <g id="SVGRepo_iconCarrier">
                                <path
                                  d="M10 12V17"
                                  stroke="#ff0000"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                                <path
                                  d="M14 12V17"
                                  stroke="#ff0000"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                                <path
                                  d="M4 7H20"
                                  stroke="#ff0000"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                                <path
                                  d="M6 10V18C6 19.6569 7.34315 21 9 21H15C16.6569 21 18 19.6569 18 18V10"
                                  stroke="#ff0000"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                                <path
                                  d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z"
                                  stroke="#ff0000"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                              </g>
                            </svg>
                          </td>
                          <td class="table-font" style="text-align: center" v-else>
                            -
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div v-else colspan="6" align="center">No data found</div>
                </div>
              </div>
            </ul>
          </div>
        </div>
        <div class="col-md-3">
          <div class="card">
            <ul class="list-group list-group-flush">
              <li class="list-group-item p-3 d-flex justify-content-between">
                <h5 class="font-weight-bold pb-2">Labour Details</h5>
                <div class="d-flex" v-if="order.status !== 'FULFILLED'">
                  <button
                    class="btn btn-primary btn-sm"
                    @click="showLabourDetailsModal = true"
                    style="font-size: 16px"
                  >
                    <svg viewBox="0 0 24 24"  width="20" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff">
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                      <g id="SVGRepo_iconCarrier"> <g id="Edit / Edit_Pencil_01"> 
                        <path id="Vector" d="M12 8.00012L4 16.0001V20.0001L8 20.0001L16 12.0001M12 8.00012L14.8686 5.13146L14.8704 5.12976C15.2652 4.73488 15.463 4.53709 15.691 4.46301C15.8919 4.39775 16.1082 4.39775 16.3091 4.46301C16.5369 4.53704 16.7345 4.7346 17.1288 5.12892L18.8686 6.86872C19.2646 7.26474 19.4627 7.46284 19.5369 7.69117C19.6022 7.89201 19.6021 8.10835 19.5369 8.3092C19.4628 8.53736 19.265 8.73516 18.8695 9.13061L18.8686 9.13146L16 12.0001M12 8.00012L16 12.0001" stroke="#fffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> 
                      </g> </g>
                    </svg>
                    Edit
                  </button>
                </div>
              </li>
              <li class="list-group-item p-3">
                <div class="table-responsive">
                  <table class="table table-borderless mb-0">
                    <tbody>
                      <tr>
                        <td class="text-muted pl-0">Hours</td>
                        <td id="notes" class="d-flex">
                          <span class="ml-1"
                            >{{
                              order.labourHours
                            }}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td class="text-muted pl-0">Rate per hour</td>
                        <td id="notes" class="d-flex">
                          <span class="ml-1"
                            >{{order.labourRatePerHour
                            ? order.labourRatePerHour.currency + " " + parseFloat(order.labourRatePerHour.amount).toFixed(2) : " - "  
                            }}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td class="text-muted pl-0">Price</td>
                        <td id="notes" class="d-flex">
                          <span class="ml-1"
                            >{{
                              order.labourPrice
                                ? order.labourPrice.currency +
                                  " " +
                                  order.labourPrice.amount
                                 : " - "
                            }}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td class="text-muted pl-0">Expected Completion in days</td>
                        <td id="notes" class="d-flex">
                          <span class="ml-1"
                            >{{
                              order.workshopExpectedCompletionDays
                                ? order.workshopExpectedCompletionDays 
                                : " - "
                            }}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- Upload Quotation -->
      <b-modal
        v-model="showUploadQuotationModal"
        id="modal-scheduled-edit"
        centered
        title="Upload"
        style="padding: 0px !important"
      >
        <div class="row">
          <div class="col-md-12 mb-1" id="app">
            <label for="Text1" class="form-label text-muted">
              Upload Files
            </label>
            <br />
            <div class="inputWrapper" style="width: 162px">
              <div
                class="btn btn-primary btn-sm"
                style="font-size: medium; cursor: pointer"
              >
                <svg
                  width="24"
                  height="24"
                  xmlns="http://www.w3.org/2000/svg"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  fill="white"
                  style="margin-right: 10px"
                >
                  <path
                    d="M11.492 10.172l-2.5 3.064-.737-.677 3.737-4.559 3.753 4.585-.753.665-2.5-3.076v7.826h-1v-7.828zm7.008 9.828h-13c-2.481 0-4.5-2.018-4.5-4.5 0-2.178 1.555-4.038 3.698-4.424l.779-.14.043-.789c.185-3.448 3.031-6.147 6.48-6.147 3.449 0 6.295 2.699 6.478 6.147l.044.789.78.14c2.142.386 3.698 2.246 3.698 4.424 0 2.482-2.019 4.5-4.5 4.5m.978-9.908c-.212-3.951-3.472-7.092-7.478-7.092s-7.267 3.141-7.479 7.092c-2.57.463-4.521 2.706-4.521 5.408 0 3.037 2.463 5.5 5.5 5.5h13c3.037 0 5.5-2.463 5.5-5.5 0-2.702-1.951-4.945-4.522-5.408"
                  />
                </svg>
                Choose File(s)
                <input
                  class="fileInput hidden"
                  @change="handleFileUpload"
                  multiple
                  type="file"
                  name="file3"
                  id="uploadQuotation"
                />
              </div>
            </div>
            <br />
          </div>
          <div v-if="files.length > 0">
            <div class="divScroll" style="border: 1px solid grey">
              <div
                class="img-tag"
                v-for="(file, index) in files"
                v-bind:key="file.name"
              >
                <div style="display: flex; align-items: center">
                  <div class="mr-2 ml-1">{{ file.name }}</div>
                  <svg
                    clip-rule="evenodd"
                    fill="red"
                    width="18"
                    height="18"
                    fill-rule="evenodd"
                    stroke-linejoin="round"
                    stroke-miterlimit="2"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    @click="removeImage(index)"
                  >
                    <path
                      d="m12 10.93 5.719-5.72c.146-.146.339-.219.531-.219.404 0 .75.324.75.749 0 .193-.073.385-.219.532l-5.72 5.719 5.719 5.719c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.385-.073-.531-.219l-5.719-5.719-5.719 5.719c-.146.146-.339.219-.531.219-.401 0-.75-.323-.75-.75 0-.192.073-.384.22-.531l5.719-5.719-5.72-5.719c-.146-.147-.219-.339-.219-.532 0-.425.346-.749.75-.749.192 0 .385.073.531.219z"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>

        <template #modal-footer>
          <div class="d-flex flex-wrap justify-content-between mt-3">
            <button
              class="btn btn-success btn-sm"
              style="font-size: 16px"
              @click="uploadQuotationFiles"
              id="saveBtn"
            >
              Submit
            </button>
          </div>
        </template>
      </b-modal>
      <!-- Part Quotation -->
      <b-modal
        v-model="showPartQuotationModal"
        id="modal-part-quotation"
        centered
        title="Create New Quotation(s)"
        style="padding: 0px !important"
      >
        <div
          class="row justify-content-between"
          style="padding: 10px; flex-wrap: initial"
        >
          <div class="col-md-3">
            <div class="h-display" style="padding: 5px">
              <label
                class="form-label font-weight-bold text-muted margin-label"
              >
                Margin (%)
              </label>
              <input
                class="custom-input-resize"
                type="number"
                placeholder="Margin"
                v-model="margin_pct"
                @input="handleMarginChange($event)"
                @keypress="isNumber($event)"
              />
            </div>
          </div>
          <div class="col-md-2 d-flex" style="justify-content: center">
            <div class="h-display" style="padding: 10px">
              <button
                class="btn btn-warning btn-sm mr-2"
                @click="copyAllAddPartQuotationToClipboard"
                style="font-size: 16px"
              >
                <svg
                  v-b-tooltip.top
                  title="Click to Copy"
                  fill="#fff"
                  width="20"
                  height="20"
                  clip-rule="evenodd"
                  fill-rule="evenodd"
                  stroke-linejoin="round"
                  stroke-miterlimit="2"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="m6 18h-3c-.48 0-1-.379-1-1v-14c0-.481.38-1 1-1h14c.621 0 1 .522 1 1v3h3c.621 0 1 .522 1 1v14c0 .621-.522 1-1 1h-14c-.48 0-1-.379-1-1zm1.5-10.5v13h13v-13zm9-1.5v-2.5h-13v13h2.5v-9.5c0-.481.38-1 1-1z"
                    fill-rule="nonzero"
                  />
                </svg>
                  Copy All
              </button>
            </div>
          </div>
        </div>
        <div class="table-overflow">
          <form @paste.prevent="handlePaste">
            <div class="row mb-0" v-for="(input, index) in inputs" :key="index">
              <div class="col-sm-6 col-md-12 d-flex">
                <div style="padding: 5px">
                  <label
                    v-if="index == 0"
                    style="padding-bottom: 20px"
                    for="inputState"
                    class="form-label font-weight-bold text-muted custom-label"
                    >Part Name *</label
                  >
                  <input
                    class="custom-input"
                    type="text"
                    placeholder="Part Name"
                    v-model="input.partName"
                    @input="handlePartNameChange($event, index)"
                  />
                </div>
                <div style="padding: 5px">
                  <label
                    v-if="index == 0"
                    style="padding-bottom: 20px"
                    class="form-label text-muted custom-label"
                  >
                    Margin (%)
                  </label>
                  <input
                    class="custom-input-resize"
                    type="number"
                    placeholder="Margin"
                    v-model="input.margin"
                    @input="handleSubMarginChange($event, index)"
                    @keypress="isNumber($event)"
                  />
                </div>
                <div style="padding: 5px">
                  <label
                    style="padding-bottom: 20px"
                    v-if="index == 0"
                    class="form-label text-muted custom-label"
                    >Unit Cost
                  </label>
                  <input
                    class="custom-input-resize"
                    type="text"
                    placeholder="Unit Cost"
                    v-model="input.unitCost"
                    @input="handleUnitCostChange($event, index)"
                    @keypress="isNumber($event)"
                  />
                </div>
                <div style="padding: 5px">
                  <label
                    style="padding-bottom: 20px"
                    v-if="index == 0"
                    class="form-label font-weight-bold text-muted custom-label"
                    >Unit Price *
                  </label>
                  <input
                    class="custom-input-resize"
                    type="text"
                    placeholder="Unit Price"
                    v-model="input.unitPrice"
                    @input="handleUnitPriceChange($event, index)"
                    @keypress="isNumber($event)"
                  />
                </div>
                <div style="padding: 5px">
                  <label
                    style="padding-bottom: 20px"
                    v-if="index == 0"
                    class="form-label text-muted custom-label"
                    >Quantity
                  </label>
                  <input
                    class="custom-input-resize"
                    type="text"
                    placeholder="Quantity"
                    v-model="input.quantity"
                    @input="handleQuantityChange($event, index)"
                    @keypress="isNumber($event)"
                  />
                </div>
                <div style="padding: 5px">
                  <label
                    style="padding-bottom: 20px"
                    v-if="index == 0"
                    class="form-label text-muted custom-label"
                    >Quality
                  </label>
                  <multiselect
                    class="multiselect-width"
                    v-model="input.quality"
                    :options="qualityArray"
                    placeholder="Select"
                    @select="handleQualityChange($event, index)"
                    :show-labels="false"
                  >
                  </multiselect>
                </div>
                <div style="padding: 5px">
                    <label
                      style="width: 110px;"
                      v-if="index == 0"
                      class="form-label text-muted custom-label"
                      >Availability (days)
                    </label>
                  <input
                    class="custom-input-resize"
                    placeholder="Availability in days"
                    v-model="input.availability"
                    @input="handleAvailabilityChange($event, index)"
                    type="number"
                    min="-1"
                  />
                </div>
                <div style="padding: 5px">
                  <label
                    style="padding-bottom: 20px"
                    v-if="index == 0"
                    class="form-label text-muted custom-label"
                    >Brand
                  </label>
                  <input
                    class="custom-input-resize"
                    style="text-align:left"
                    type="text"
                    placeholder="Brand"
                    v-model="input.brand"
                    @input="handleBrandChange($event, index)"
                  />
                </div>
                <div style="padding: 5px">
                  <label
                    style="padding-bottom: 20px"
                    v-if="index == 0"
                    class="form-label text-muted custom-label"
                    >Part No.
                  </label>
                  <input
                    class="custom-input"
                    type="text"
                    placeholder="Part No."
                    v-model="input.partNumber"
                    @input="handlePartNumberChange($event, index)"
                  />
                </div>
                <div style="padding: 5px">
                  <label
                    style="padding-bottom: 20px"
                    v-if="index == 0"
                    class="form-label text-muted custom-label"
                  >
                    Total Price
                  </label>
                  <input
                    class="custom-input-resize"
                    type="text"
                    :disabled="true"
                    placeholder="Price"
                    v-model="input.price"
                  />
                </div>
                <div style="padding: 5px" v-if="inputs.length > 1">
                  <label
                    style="padding-bottom: 20px"
                    v-if="index == 0"
                    class="form-label text-muted custom-label"
                  >
                  </label>
                  <div
                    :style="
                      index == 0 ? 'padding-top: 20px' : 'padding-top: 6px'
                    "
                  >
                    <svg
                      @click="deleteRow(index)"
                      style="cursor: pointer"
                      viewBox="0 0 24 24"
                      fill="none"
                      width="20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        <path
                          d="M10 12V17"
                          stroke="#ff0000"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M14 12V17"
                          stroke="#ff0000"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M4 7H20"
                          stroke="#ff0000"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M6 10V18C6 19.6569 7.34315 21 9 21H15C16.6569 21 18 19.6569 18 18V10"
                          stroke="#ff0000"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z"
                          stroke="#ff0000"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div>
            <label
              v-if="isPartNameEmpty"
              class="text-danger ml-1"
              >Part Name is required & should be less than 60 characters</label
            >
          </div>
          <div>
            <label
              v-if="isUnitPriceEmpty"
              class="text-danger ml-1"
              >Unit Price should be greater than 0</label
            >
          </div>
        </div>

        <div class="wrap mt-3">
          <button
            class="btn btn-sm btn-primary"
            @click="addRow"
            id="add-service"
            style="font-size: 16px"
          >
            Add Part Quotation
          </button>
        </div>
        <template #modal-footer>
          <div style="display: block !important">
            <div
              v-if="total_amount !== null"
              class="h-display"
              style="padding-right: 15px"
            >
              <label class="form-label font-weight-bold text-muted total-label">
                Total Amount
              </label>
              <div class="total-display">{{ total_amount }} {{ order.labourRatePerHour.currency }}</div>
            </div>
            <div class="w-100">
              <b-button
                size="sm"
                class="float-right btn btn-success btn-sm"
                @click="onSubmitPartQuotation"
                style="font-size: 16px"
              >
                Submit Quotations
              </b-button>
            </div>
          </div>
        </template>
      </b-modal>
      <!-- Confirmation for Accept / Reject -->
      <b-modal
        v-model="confirmModalShow"
        id="modal-scheduled-edit"
        centered
        :title="this.isAccept ? 'Accept Order' : 'Reject Order'"
      >
        <div class="row" style="padding: 10px">
          <div class="col-md-12">
            <p v-if="this.isAccept && order.workshopExpectedCompletionAt">
              Expected Completion is on
              {{ order.workshopExpectedCompletionAt | DateTimeFormat }}
            </p>
            <p>
              Are you sure you want to
              {{ this.isAccept ? "Accept" : "Reject" }} the order?
            </p>
          </div>
        </div>

        <template #modal-footer>
          <div class="w-100">
            <button
              variant="success"
              size="sm"
              class="btn btn-success float-right btn-sm order-add"
              style="font-size: medium; cursor: pointer"
              @click="handleYesModal"
            >
              Yes
            </button>

            <button
              variant="danger"
              size="sm"
              class="btn btn-danger float-right btn-sm mr-3 order-add"
              style="font-size: medium; cursor: pointer"
              @click="handleNoModal"
            >
              No
            </button>
          </div>
        </template>
      </b-modal>
      <!-- Update Fully Quoted -->
      <b-modal
        v-model="isFullyQuotedModal"
        id="modal-fully-quoted"
        centered
        hide-header
        hide-footer
      >
        <div class="row" style="padding: 10px">
          <div class="col-md-12">
            <p>
              Is part quotation is "Partially Quoted" or "Fully Quoted"?
            </p>
          </div>
          <div class="col-md-6">            
            <button
              variant="warning"
              size="sm"
              class="btn btn-warning btn-sm mr- 2 order-add"
              style="font-size: medium; cursor: pointer"
              @click="handlePartiallyQuoted"
            >
              Partially Quoted
            </button>
          </div>
          <div class="col-md-6">
            <button
              variant="success"
              size="sm"
              class="btn btn-success float-right btn-sm order-add"
              style="font-size: medium; cursor: pointer"
              @click="handleFullyQuoted"
            >
              Fully Quoted
            </button>
          </div>
        </div>
      </b-modal>
      <!-- Confirmation for Delete Part Quotation -->
      <b-modal
        v-model="showPartQuotationDeleteModal"
        id="modal-scheduled-edit"
        centered
        title="Delete Part Quotation"
      >
        <div class="row" style="padding: 10px">
          <div class="col-md-12">
            <p>
              Are you sure you want to delete this part quotation?
            </p>
          </div>
        </div>

        <template #modal-footer>
          <div class="w-100">
            <button
              variant="success"
              size="sm"
              class="btn btn-success float-right btn-sm order-add"
              style="font-size: medium; cursor: pointer"
              @click="deletePartQuotation"
            >
              Yes
            </button>

            <button
              variant="danger"
              size="sm"
              class="btn btn-danger float-right btn-sm mr-3 order-add"
              style="font-size: medium; cursor: pointer"
              @click="hideDeletePartQuotationModal"
            >
              No
            </button>
          </div>
        </template>
      </b-modal>
      <!-- Update Labour Details -->
      <b-modal
        v-model="showLabourDetailsModal"
        id="modal-labour-details"
        centered
        title="Update Labour Details"
      >
        <div class="row" style="padding: 10px">
          <div class="col-md-6">
              <label
                class="form-label font-weight-bold text-muted margin-label"
              >
                Hours
              </label>
          </div>
          <div class="col-md-6">
              <input
                class="custom-input-labour"
                type="number"
                placeholder="0"
                v-model="labourHours"
                min="0"
                max="24"
                @input="handleLabourHoursChange($event)"
                @keypress="isNumber($event)"
              />
          </div>
          <div class="col-md-6">
              <label
                class="form-label font-weight-bold text-muted margin-label"
              >
                Price
              </label>
          </div>
          <div class="col-md-6">
              <input
                class="custom-input-labour"
                type="number"
                placeholder="0"
                v-model="labourPrice"
                @input="handleLabourPriceChange($event)"
                @keypress="isNumber($event)"
              />
          </div>
          <div class="col-md-6">
              <label
                class="form-label font-weight-bold text-muted margin-label"
              >
                Rate per hour
              </label>
          </div>
          <div class="col-md-6" style="padding-top: 10px; padding-right: 40px;">
              <span class="ml-1"
                >{{
                  ratePerHour == 0.00 || ratePerHour == 0 || ratePerHour == null
                    ? " - "
                    :  ratePerHour
                }}
              </span>
          </div>
          <div class="col-md-6">
            <label
              class="form-label font-weight-bold text-muted margin-label"
            >
              Expected Completion in days
            </label>
          </div>
          <div class="col-md-6">
            <input
              class="custom-input-labour"
              type="number"
              placeholder="0"
              v-model="workshopExpectedCompletionDays"
              @change="handleWorkshopExpectedCompletionDaysChange"
              min="0"
              @keypress="isNumber($event)"
            />
          </div>
        </div>

        <template #modal-footer>
          <div class="w-100">
            <button
              variant="success"
              size="sm"
              class="btn btn-success float-right btn-sm order-add"
              style="font-size: medium; cursor: pointer"
              @click="handleUpdateLabourDetails"
            >
              Update
            </button>
          </div>
        </template>
      </b-modal>

      <!-- Add Comment -->
      <b-modal
        v-model="commentModalShow"
        id="modal-comment"
        centered
        title="Add Comment"
        size="md"
      >
        <div>
          <div class="col-md-12 mb-3">
            <label
              for="car-model"
              class="form-label font-weight-bold text-muted"
            >
              Comment *
            </label>
            <div>
              <textarea
                rows="4"
                class="custom-input"
                type="text"
                v-model="comment"
              ></textarea>
            </div>
          </div>
        </div>

        <template #modal-footer>
          <div class="w-100">
            <b-button
              variant="primary"
              size="sm"
              class="float-right order-add"
              @click="commentUpdate"
            >
              Submit
            </b-button>
          </div>
        </template>
      </b-modal>


      <!-- Job Update Modal -->
      <b-modal
        v-model="showJobUpdateModal"
        centered
        :title="('Update Job - for ' + this.jobType) | customTitleCase"
        size="md"
      >
        <div class="form-label text-muted filter-label-heading">
          <label class="form-label text-muted filter-label-heading mt-2"
            >Scheduled At</label
          >
          <datetime
            v-model="jobScheduledAt"
            class="scheduled-at"
            type="datetime"
            input-class="custom-input"
            value-zone="Asia/Dubai"
            :format="{
              year: 'numeric',
              month: 'long',
              day: 'numeric',
              hour: 'numeric',
              minute: '2-digit',
            }"
            :phrases="{ ok: 'Continue', cancel: 'Exit' }"
            :hour-step="1"
            :minute-step="15"
            :week-start="7"
            use12-hour
            auto
          ></datetime>
        </div>
        <div class="form-label text-muted filter-label-heading" v-if="this.jobType == 'WORKSHOP_SERVICE'">
          <label class="form-label text-muted filter-label-heading mt-2"
            >Scheduled Upto</label
          >
          <datetime
            v-model="jobScheduledUpto"
            class="scheduled-at"
            type="datetime"
            input-class="custom-input"
            value-zone="Asia/Dubai"
            :format="{
              year: 'numeric',
              month: 'long',
              day: 'numeric',
              hour: 'numeric',
              minute: '2-digit',
            }"
            :phrases="{ ok: 'Continue', cancel: 'Exit' }"
            :hour-step="1"
            :minute-step="15"
            :week-start="7"
            use12-hour
            auto
          ></datetime>
        </div>
        <div class="mb-3">
          <label class="form-label text-muted filter-label-heading mt-2"
            >Assigned To</label
          >
          <multiselect
            v-model="jobAssignedTo"
            :options="jobAssignedToArray"
            :multiple="false"
            :custom-label="customLabelJobAssignedTo"
            :show-labels="false"
            :allow-empty="false"
            :searchable="true"
            :internal-search="false"
            label="name"
            key="name"
            placeholder="Type to search (at least 3 characters)"
            @search-change="handleJobAssignedToSearch"
          >
          </multiselect>
        </div>
        <div
          class="form-label text-muted filter-label-heading mt-3">
          <label
            for="car-model"
            class="form-label text-muted filter-label-heading mt-1"
            >Notes</label
          >
          <textarea
            rows="2"
            class="custom-input notesArea"
            type="text"
            v-model="jobNotes"
          ></textarea>
        </div>
        <div class="form-label text-muted filter-label-heading mt-3">
          <label
            for="job-hours-required"
            class="form-label text-muted filter-label-heading mt-1"
            >Hours Required
          </label>
          <input
            class="custom-input"
            type="number"
            step="0.01"
            v-model="jobHoursRequired"
            id="job-hours-required"
          />
        </div>

        <template #modal-footer>
          <div class="w-100">
            <button
              v-if="jobScheduledAt"
              variant="warning"
              size="sm"
              class="btn btn-warning float-left btn-sm mr-3 order-add"
              style="font-size: medium; cursor: pointer"
              @click="unscheduleJob"
            >
              Unschedule
            </button>
            <button
              variant="success"
              size="sm"
              class="btn btn-success float-right btn-sm order-add"
              style="font-size: medium; cursor: pointer"
              @click="handleUpdateJobInfoApi"
              :disabled="isJobUpdating"
            >
              Update
            </button>
          </div>
        </template>
      </b-modal>

    </div>

    <div id="load-spinner">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
</template>
<script>
import { GET_ORDER_DETAILS } from "../../../graphql/query";
import {
  WORKSHOP_ORDER_ATTACHMENT_UPLOAD,
  WORKSHOP_ORDER_UPDATE,
  WORKSHOP_PART_QUOTATION_CREATE,
  WORKSHOP_PART_QUOTATION_DELETE,
  WORKSHOP_JOB_CREATE,
  WORKSHOP_JOB_UPDATE,
  JOB_UPDATE,
} from "../../../graphql/mutation";
import { GET_JOB_USERS  } from "../../../graphql/query";
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
import Vue from "vue";
Vue.use(Datetime);
export default {
  name: "OrderDetails",
  components: {
    datetime: Datetime,
  },
  data() {
    return {
      order: [],
      jobId: null,
      jobType: "",
      jobAssignedTo: null,
      jobAssignedToArray: [],
      jobScheduledAt: null,
      jobScheduledUpto: null,
      jobNotes: "",
      jobAssignedToSearch: "",
      jobHoursRequired: null,
      isJobUpdating: false,
      showJobUpdateModal: false,
      partQuotations: [],
      files: [],
      titles: [],
      confirmModalShow: false,
      isUnitPriceEmpty: false,
      isPartNameEmpty: false,
      isAccept: false,
      isReject: false,
      showExpired: false,
      checkedShowExpired: false,
      isFullyQuoted: null,
      isFullyQuotedModal: false,
      fullyQuotedArray: ["Fully Quoted", "Partially Quoted"],
      showUploadQuotationModal: false,
      showLabourDetailsModal: false,
      labourHours: null,
      labourPrice: null,
      ratePerHour: null,
      workshopExpectedCompletionDays: null,
      approvedQuotationName: null,
      pq_id: null,
      showPartQuotationModal: false,
      showPartQuotationDeleteModal: false,
      is_edit : false,
      comment: "",
      commentModalShow : false,
      qualityArray: ["Genuine", "Aftermarket"],
      total_amount: 0,
      margin_pct: 0,
      inputs: [
        {
          partName: "",
          margin: this.margin_pct,
          unitCost: 0,
          unitPrice: 0,
          quantity: 1,
          quality: "Genuine",
          availability: 0,
          brand: "",
          partNumber: "",
          price: 0,
        },
      ],
      minusSvg: require("../../../assets/images/svg/minus.svg"),
    };
  },
  mounted() {
    this.getOrderDetails();
  },
  computed: {},
  props: ["orderId"],
  methods: {
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    showToast(message, variant) {
      this.$bvToast.toast(message, {
        toaster: "b-toaster-top-center",
        variant: variant ? variant : "danger",
        solid: true,
        toastClass: "text-center toast-box toast-style",
      });
    },
    handleShowExpired() {
      this.showExpired = this.checkedShowExpired;
      if (this.showExpired) {
        this.partQuotations = this.order.partQuotations.filter((pq) => {
          if (pq.status === "EXPIRED") {
            return pq;
          }
        });
      } else {
        this.partQuotations = this.order.partQuotations.filter((pq) => {
          if (pq.status !== "EXPIRED") {
            return pq;
          }
        });
      }
    },
    handleLabourHoursChange(event) {
      if(event.target.value > 0){
        this.labourHours = event.target.value;
        if(this.labourHours > 0 && this.labourPrice > 0){
          this.ratePerHour = parseFloat(this.labourPrice / this.labourHours).toFixed(2);
          if(this.ratePerHour % 1 === 0){
           this.ratePerHour.toFixed(0);
          } else {
            this.ratePerHour.toFixed(2);
          }
        } else {
          this.ratePerHour = 0;
        }
      }
    },
    handleLabourPriceChange(event) {
      if(event.target.value > 0){
        this.labourPrice = event.target.value;
        if(this.labourHours > 0 && this.labourPrice > 0){
          this.ratePerHour = parseFloat(this.labourPrice / this.labourHours).toFixed(2);
          if(this.ratePerHour % 1 === 0){
           this.ratePerHour.toFixed(0);
          } else {
            this.ratePerHour.toFixed(2);
          }
        } else {
          this.ratePerHour = 0;
        }
      }
    },
    handleWorkshopExpectedCompletionDaysChange(event) {
      this.workshopExpectedCompletionDays = event.target.value;
    },
    handleJobCreate(type) {
      if (window.confirm("Are you sure you want to add a " + type + " job?")) {
        this.$apollo
          .mutate({
            mutation: WORKSHOP_JOB_CREATE,
            variables: {
              orderId: this.order.number,
              type: type == "workshop" ? "WORKSHOP_SERVICE" : "PARTS",
            },
          })
          .then((data) => {
            if (data.data.workshopJobCreate.errors.length == 0) {
              this.showToast("Workshop Job added successfully", "success");
              this.getOrderDetails();
            } else {
              this.showToast(data.data.workshopJobCreate.errors[0].message, "danger");
            }
          })
      }
    },
    handleAddWorkshopJob() {
      this.handleJobCreate("workshop");
    },
    handleAddPartsJob() {
      this.handleJobCreate("parts");
    },
    customLabelJobAssignedTo(option) {
      if(option.firstName) {
        return option.firstName + " " + option.lastName;
      }
    },
    handleJobAssignedToSearch(search) {
      this.jobAssignedToSearch = search;
      if(!this.jobAssignedToSearch || this.jobAssignedToSearch.length < 3){
        return;
      }
      this.$apollo
        .mutate({
          mutation: GET_JOB_USERS,
          variables: {
            jobID: this.jobId,
            first: 10,
            search: this.jobAssignedToSearch,
          },
        })
        .then((data) => {
          this.jobAssignedToArray = data.data.jobUsers.edges.map((res) => {
            return res.node;
          });
        });
    },
    handleUpdateJobInfo(job, type) {
      this.jobId = job.id;
      this.jobType = type == "parts" ? "PARTS" : "WORKSHOP_SERVICE";
      this.jobAssignedTo = job.assignedTo ? job.assignedTo : null;
      this.jobAssignedToSearch = "";
      this.jobScheduledAt = job.scheduledAt;
      this.jobScheduledUpto = job.scheduledUpto;
      this.jobNotes = job.notes;
      this.jobHoursRequired = job.hoursRequired;
      this.showJobUpdateModal = true;
    },
    unscheduleJob() {
      if(window.confirm("Are you sure you want to unschedule the job?")) {
        this.jobAssignedTo = null;
        this.jobScheduledAt = null;
        this.jobScheduledUpto = null;
        this.jobNotes = "";
        this.jobHoursRequired = null;
        this.handleUpdateJobInfoApi(true);
        this.showJobUpdateModal = false;
      }
    },
    handleUpdateJobInfoApi(unschedule) {
      this.isJobUpdating = true;
      if (unschedule !== true && this.jobScheduledAt && this.jobScheduledUpto && !this.jobHoursRequired) {
        this.showToast("Please enter the hours required", "danger");
        this.isJobUpdating = false;
        return;
      }
      this.$apollo
        .mutate({
          mutation: WORKSHOP_JOB_UPDATE,
          variables: unschedule === true
            ? {jobId: this.jobId, unschedule: true}
            : {
              jobId: this.jobId,
              assignedTo: this.jobAssignedTo ? (this.jobAssignedTo.id || this.jobAssignedTo.phone) : null,
              scheduledAt: this.jobScheduledAt || null,
              scheduledUpto: this.jobScheduledUpto || null,
              notes: this.jobNotes ? this.jobNotes.trim() : null,
              hoursRequired: this.jobHoursRequired || null,
            },
        })
        .then((data) => {
          if (data.data.workshopJobUpdate.errors.length == 0) {
            this.showToast("Job updated successfully", "success");
            this.getOrderDetails();
            this.showJobUpdateModal = false;
            this.isJobUpdating = false;
          } else {
            this.showToast(data.data.workshopJobUpdate.errors[0].message, "danger");
            this.isJobUpdating = false;
          }
        })
    },
    handleUpdateLabourDetails() {
      if(this.labourHours == null && this.labourPrice == null){
        this.showToast("Please update with the labour details", "danger");
      } else if(this.labourHours > 24){
        this.showToast("Labour Hours should be less than or equal to 24", "danger");
      }
      else{
      this.$apollo
        .mutate({
          mutation: WORKSHOP_ORDER_UPDATE,
          variables: {
            id: this.order.number,
            labourHours: parseFloat(this.labourHours).toFixed(2),
            labourPrice: parseFloat(this.labourPrice).toFixed(2),
            workshopExpectedCompletionDays: parseInt(this.workshopExpectedCompletionDays)
          },
        })
        .then((data) => {
          if (data.data.workshopOrderUpdate.errors.length == 0) {
            this.showLabourDetailsModal = false;
            this.order = data.data.workshopOrderUpdate.order;
            this.showToast("Labour Details updated successfully", "success");
          } else {
            this.showToast(data.data.workshopOrderUpdate.errors[0].message, "danger");
          }
        })
        .catch((err) => {
          console.log(err);
        });
      }
    },
    addRow() {
      this.inputs.push({
        partName: "",
        margin: this.margin_pct,
        unitCost: 0,
        unitPrice: 0,
        quantity: 1,
        quality: "Genuine",
        brand: "",
        partNumber: "",
        availability: 0,
        price: 0,
      });
    },
    partsJobConfirmAction(action){
      // show dialog box to confirm the action
      if (window.confirm("Are you sure you want to mark the job as " + action + "?")) {
        this.partsJobNextAction(action);
      }
    },
    partsJobNextAction(action){
      this.$apollo
      .mutate({
        mutation: JOB_UPDATE,
        variables: {
          id: this.order.partsJob.id,
          action: action,
        },
      })
      .then((data) => {
        if (data.data.jobUpdate.errors.length == 0) {
          this.showToast("Job updated successfully", "success");
          this.getOrderDetails();
        } else {
          this.showToast(data.data.jobUpdate.errors[0].message, "danger");
        }
      })
    },
    deleteRow(index) {
      // Deleting Row
      this.inputs.splice(index, 1);
      this.total_amount = 0
      for (let i = 0; i < this.inputs.length; i++) {
        this.total_amount = parseFloat(
          parseFloat(this.total_amount) + parseFloat(this.inputs[i].price)
        ).toFixed(2);
      }
    },
    showDeletePartQuotationModal(id) {
      this.pq_id = id;
      this.showPartQuotationDeleteModal = true;
    },
    hideDeletePartQuotationModal() {
      this.showPartQuotationDeleteModal = false;
    },
    deletePartQuotation(){
      this.$apollo
      .mutate({
        mutation: WORKSHOP_PART_QUOTATION_DELETE,
        variables: {
          id: this.pq_id,
        },
      })
      .then((data) => {
          if (data.data.workshopPartQuotationDelete.errors.length == 0) {
            if(this.is_edit == false){
              this.showPartQuotationDeleteModal = false;
              this.showToast("Part Quotation marked as expired successfully", "success");
              this.getOrderDetails();
            }
          } else {
            this.showToast(data.data.workshopPartQuotationDelete.errors[0].message, "danger");
          }
          this.is_edit = false;
      })
      .catch((err) => {
        console.log(err);
      });
    },
    copyAllAddPartQuotationToClipboard(){
      // Check if there are partQuotations in the order
      if (this.inputs && this.inputs.length > 0) {
        // Create a string to hold the copied data
        let copiedData = "Part Name\tMargin\tUnit Cost\tUnit Price\tQuantity\tQuality\tAvailability\tBrand\tPart No.\tPrice\n";
        // Loop through each partQuotation and append its data to the string
        this.inputs.forEach((pq) => {    
          copiedData += `${pq.partName}\t${pq.margin}\t${pq.unitCost}\t${pq.unitPrice}\t${pq.quantity}\t${pq.quality}\t${pq.availability}\t${pq.brand ? pq.brand : '-'}\t${pq.partNumber ? pq.partNumber : '-'}\t${pq.price}\n`;
        });
        // Create a textarea element to hold the copied data
        const textarea_add = document.createElement('textarea');
        textarea_add.value = copiedData;

        // Append the textarea to the DOM
        document.body.appendChild(textarea_add);

        // Select and copy the data
        textarea_add.select();
        textarea_add.focus();
        document.execCommand('copy');

        // Remove the textarea from the DOM
        document.body.removeChild(textarea_add);

        this.showToast("All the part quotations copied to clipboard!", "success");
      } else {
        this.showToast("No part quotations to copy!", "danger");
      }
    },
    copyAllPartQuotationToClipboard() {
      // Check if there are partQuotations in the order
      if (this.order.partQuotations && this.order.partQuotations.length > 0) {
        // Create a string to hold the copied data
        let copiedData = "Part Name\tUnit Price\tQuantity\tPrice\tQuality\tStatus\tBrand\tPart No.\tCreated By\tCreated At\n";

        // Loop through each partQuotation and append its data to the string
        this.order.partQuotations.forEach((pq) => {       
          copiedData += `${pq.partName}\t${pq.unitPriceSupplier.currency} ${pq.unitPriceSupplier.amount}\t${pq.quantity}\t${pq.priceSupplier.currency} ${pq.priceSupplier.amount}\t${pq.quality}\t${pq.status}\t${pq.brand ? pq.brand : '-'}\t${pq.partNumber ? pq.partNumber : '-'}\t${pq.createdBy.firstName} ${pq.createdBy.lastName}\t${pq.createdAt}\n`;
        });

        // Create a textarea element to hold the copied data
        const textarea = document.createElement('textarea');
        textarea.value = copiedData;

        // Append the textarea to the DOM
        document.body.appendChild(textarea);

        // Select and copy the data
        textarea.select();
        document.execCommand('copy');

        // Remove the textarea from the DOM
        document.body.removeChild(textarea);

        this.showToast("All part quotations copied to clipboard!", "success");
      } else {
        this.showToast("No part quotations to copy!", "danger");
      }
    },
    addPartQuotation() {
      this.showPartQuotationModal = true;
      this.isPartNameEmpty = false;
      this.isUnitPriceEmpty = false;
      this.total_amount = 0;
      this.inputs = [
        {
          partName: "",
          margin: this.margin_pct,
          unitCost: 0,
          unitPrice: 0,
          quantity: 1,
          price: 0,
          quality: "Genuine",
          brand: "",
          availability: 0,
          partNumber: "",
        },
      ];
    },
    handlePaste(event) {
      event.preventDefault();

      const clipboardData = event.clipboardData || window.clipboardData;
      const pastedData = clipboardData.getData("text");

      // Assuming data is tab-separated, you might need to adjust based on your actual data
      let rows = pastedData.split("\n").map((row) => row.split("\t"));

      const expectedHeadings = ["part name", "margin", "unit cost", "unit price", "quantity", "quality", "availability", "brand", "part no"];
      const headingsMatched = rows.length > 0 && rows[0].some((value) => expectedHeadings.includes(value));

      if (headingsMatched) {
        rows = rows.slice(1); // Remove the first row (headings)
      }

      // Update the Vue component state with the parsed data
      for (let i = 0; i < rows.length; i++) {
        const rowData = rows[i];
        let margin = rowData[1] || this.margin_pct;
        let unitCost = rowData[2] || 0;
        let unitPrice = rowData[3] || 0; 
        let quantity = rowData[4] || 1;
        let price = 0.0;       
        if(rowData[1] == 0){
          margin = this.margin_pct;
        }      
        if(unitCost <= 0){
          unitCost = parseFloat(
            parseFloat(unitPrice) /
              parseFloat(1 + margin / 100)
          ).toFixed(2);
        }
        if(unitPrice > 0 && unitCost > 0) {
          unitPrice = parseFloat(
            parseFloat(unitCost) +
              parseFloat((unitCost * margin) / 100)
          ).toFixed(2);
        }  
        if(quantity == "0"){
          quantity = 1;
        }
        if (i < this.inputs.length) {
          this.inputs[i].partName = rowData[0] || "";
          this.inputs[i].margin = margin;
          this.inputs[i].unitCost = unitCost;
          this.inputs[i].unitPrice = unitPrice;
          this.inputs[i].quantity = quantity;
          this.inputs[i].quality = rowData[5] || "Genuine";
          this.inputs[i].availability = rowData[6] || 0;
          this.inputs[i].brand = rowData[7] || "";
          this.inputs[i].partNumber = rowData[8] || "";
          if (this.inputs[i].unitPrice === 0) {
            this.inputs[i].price = 0.0;
          } else {
            this.inputs[i].price =
              parseFloat(
                parseFloat(this.inputs[i].unitPrice) *
                  parseFloat(this.inputs[i].quantity)
              ).toFixed(2) || 0;
          }
          this.total_amount = parseFloat(
            parseFloat(this.total_amount) + parseFloat(this.inputs[i].price)
          ).toFixed(2);
        } else {
          if (
            rowData[3] == "undefined" ||
            rowData[3] == "" ||
            rowData[3] == null
          ) {
            price = 0.0;
          } else {
            price = parseFloat(
              parseFloat(rowData[3]) * parseFloat(quantity)
            ).toFixed(2);
          }
          this.inputs.push({
            partName: rowData[0] || "",
            margin: margin,
            unitCost: unitCost,
            unitPrice: unitPrice,
            quantity: quantity,
            quality: rowData[5] || "Genuine",
            availability: rowData[6] || 0,
            brand: rowData[7] || "",
            partNumber: rowData[8] || "",
            price: price,
          });
        }
        this.total_amount = parseFloat(
          parseFloat(this.total_amount) + parseFloat(this.inputs[i].price)
        ).toFixed(2);
      }
    },
    handlePartNameChange(e, index) {
      this.isPartNameEmpty = false;
      this.inputs[index].partName = e.target.value;
      if (this.inputs[index].partName.length > 60) {
        this.isPartNameEmpty = true;
      }
    },
    handleMarginChange(e) {
      this.margin_pct = e.target.value;
      localStorage.setItem("margin", this.margin_pct);
      this.total_amount = 0;
      this.inputs.map((input) => {
        input.unitPrice = parseFloat(
          parseFloat(input.unitCost) +
            parseFloat((input.unitCost * this.margin_pct) / 100)
        ).toFixed(2);
        input.price = parseFloat(
          parseFloat(input.unitPrice) * parseFloat(input.quantity)
        ).toFixed(2);
        input.margin = this.margin_pct;
        this.total_amount = parseFloat(
          parseFloat(this.total_amount) + parseFloat(input.price)
        ).toFixed(2);
      });
    },
    handleSubMarginChange(e, index) {
      this.inputs[index].margin = e.target.value;
      this.inputs[index].unitPrice = parseFloat(
        parseFloat(this.inputs[index].unitCost) +
          parseFloat(
            (this.inputs[index].unitCost * this.inputs[index].margin) / 100
          )
      ).toFixed(2);
      this.inputs[index].price = parseFloat(
        parseFloat(this.inputs[index].unitPrice) *
          parseFloat(this.inputs[index].quantity)
      ).toFixed(2);
      let total_amt = 0
      for (let i = 0; i < this.inputs.length; i++) {
        total_amt = parseFloat(
          parseFloat(total_amt) + parseFloat(this.inputs[i].price)
        ).toFixed(2);
      }
      this.total_amount = total_amt;
    },
    handleUnitCostChange(e, index) {
      this.isUnitPriceEmpty = false;
      let marginPct = this.inputs[index].margin ? this.inputs[index].margin : this.margin_pct;
      this.inputs[index].unitCost = e.target.value;
      this.inputs[index].unitPrice = parseFloat(
        parseFloat(this.inputs[index].unitCost) +
          parseFloat((this.inputs[index].unitCost * marginPct) / 100)
      ).toFixed(2);
      this.inputs[index].price = parseFloat(
        parseFloat(this.inputs[index].unitPrice) *
          parseFloat(this.inputs[index].quantity)
      ).toFixed(2);
      let total_amt = 0
      for (let i = 0; i < this.inputs.length; i++) {
        total_amt = parseFloat(
          parseFloat(total_amt) + parseFloat(this.inputs[i].price)
        ).toFixed(2);
      }
      this.total_amount = total_amt;
    },
    handleUnitPriceChange(e, index) {
      this.isUnitPriceEmpty = false;
      let marginPct = this.inputs[index].margin ? this.inputs[index].margin : this.margin_pct;
      this.inputs[index].unitPrice = e.target.value;
      this.inputs[index].unitCost = parseFloat(
        parseFloat(this.inputs[index].unitPrice) /
          parseFloat(1 + marginPct / 100)
      ).toFixed(2);
      this.inputs[index].price = parseFloat(
        parseFloat(this.inputs[index].unitPrice) *
          parseFloat(this.inputs[index].quantity)
      ).toFixed(2);
      let total_amt = 0
      for (let i = 0; i < this.inputs.length; i++) {
        total_amt = parseFloat(
          parseFloat(total_amt) + parseFloat(this.inputs[i].price)
        ).toFixed(2);
      }
      this.total_amount = total_amt;
    },
    handleQuantityChange(e, index) {
      this.inputs[index].quantity = e.target.value;
      this.inputs[index].price = parseFloat(
        parseFloat(this.inputs[index].unitPrice) *
          parseFloat(this.inputs[index].quantity)
      ).toFixed(2);
      let total_amt = 0
      for (let i = 0; i < this.inputs.length; i++) {
        total_amt = parseFloat(
          parseFloat(total_amt) + parseFloat(this.inputs[i].price)
        ).toFixed(2);
      }
      this.total_amount = total_amt;
    },
    handleQualityChange(e, index) {
      if (e == "Genuine") {
        this.inputs[index].quality = "GENUINE";
      } else {
        this.inputs[index].quality = "AFTERMARKET";
      }
    },
    handleAvailabilityChange(e, index) {
      this.inputs[index].availability = e.target.value;
    },
    handleBrandChange(e, index) {
      this.inputs[index].brand = e.target.value;
    },
    handlePartNumberChange(e, index) {
      this.inputs[index].partNumber = e.target.value;
    },
    hideFullyQuotedModal() {
      this.isFullyQuotedModal = false;
    },
    onSubmitPartQuotation() {
      this.inputs.filter((input) => {
        if (!input.partName || input.partName.length > 60) {
          return this.isPartNameEmpty = true;
        }
        if(input.unitPrice == 0){
          return this.isUnitPriceEmpty = true;
        }
      });
      if(this.isPartNameEmpty == false && this.isUnitPriceEmpty == false){
        this.isFullyQuotedModal = true;
        this.isFullyQuoted = null;
      }
    },
    handlePartiallyQuoted() {
      this.isFullyQuoted = false;
      this.handleCreatePartQuotation();
    },
    handleFullyQuoted(){
      this.isFullyQuoted = true;
      this.handleCreatePartQuotation();
    },
    handleCreatePartQuotation() {
      if(this.isFullyQuoted == null){
        this.showToast("Please update quoted status", "danger");
      } 
      let partinputs = [];
      if(this.isPartNameEmpty == false && this.isUnitPriceEmpty == false){    
        this.inputs.map((input) => {
          partinputs.push({
            partName: input.partName,
            quantity: input.quantity == "0" ? 1 : input.quantity,
            priceSupplier: input.price,
            quality: input.quality.toUpperCase(),
            availability: input.availability,
            brand: input.brand,
            partNumber: input.partNumber,
          });
        });
        this.$apollo
          .mutate({
            mutation: WORKSHOP_PART_QUOTATION_CREATE,
            variables: {
              orderId: this.orderId,
              input: partinputs,
              isFullyQuoted: this.isFullyQuoted,
            },
          })
          .then((data) => {
          if (data.data.workshopPartQuotationsCreate.errors.length == 0) {
            this.isFullyQuotedModal = false;
            this.showPartQuotationModal = false;
            this.showToast("Part Quotation Created Successfully", "success");
            this.inputs = [
              {
                partName: "",
                margin: 0,
                unitCost: 0,
                unitPrice: 0,
                quantity: 1,
                quality: "Genuine",
                brand: "",
                partNumber: "",
                availability: 0,
                price: 0,
              },
            ];
            this.showPartQuotationModal = false;
            this.order.partQuotations = data.data.workshopPartQuotationsCreate.partQuotations;
            this.order.isFullyQuoted = data.data.workshopPartQuotationsCreate.isFullyQuoted;
            this.partQuotations = data.data.workshopPartQuotationsCreate.partQuotations.filter(
              (pq) => {
                if (pq.status != "EXPIRED") {
                  return pq;
                }
              }
            );

          } else {
            this.errors = data.data.workshopPartQuotationsCreate.errors;
            this.errors.map((e) => {
              this.showToast(e.message, "danger");
            });
          }
        });
      }
    },
    handleAccept() {
      this.confirmModalShow = true;
      this.isAccept = true;
      this.isReject = false;
    },
    handleReject() {
      this.confirmModalShow = true;
      this.isAccept = false;
      this.isReject = true;
    },
    handleSendToTrello() {
      this.$apollo
        .query({
          query: WORKSHOP_ORDER_UPDATE,
          variables: {
            id: this.orderId,
            sendToTrello: true,
          },
        })
        .then((data) => {
          if (data.data.workshopOrderUpdate.errors.length == 0) {
            this.showToast("Order sent to Trello successfully", "success");
          } else {
            this.errors = data.data.workshopOrderUpdate.errors;
            this.errors.map((e) => {
              this.showToast(e.message, "danger");
            });
          }
        });
    },
    handleYesModal() {
      this.confirmModalShow = false;
      this.$apollo
        .query({
          query: WORKSHOP_ORDER_UPDATE,
          variables: {
            id: this.orderId,
            action: this.isAccept ? "ACCEPTED" : "REJECTED",
          },
        })
        .then((data) => {
          if (data.data.workshopOrderUpdate.errors.length == 0) {
            this.showToast("Order Updated Successfully", "success");
            this.getOrderDetails();
          } else {
            this.errors = data.data.workshopOrderUpdate.errors;
            this.errors.map((e) => {
              this.showToast(e.message, "danger");
            });
          }
        });
    },
    handleNoModal() {
      this.confirmModalShow = false;
    },
    async getOrderDetails() {
      document.getElementById("load-spinner").style.display = "flex";
      document.getElementById("main-div").style.display = "none";
      this.margin_pct = localStorage.getItem("margin");
      await this.$apollo
        .query({
          query: GET_ORDER_DETAILS, 
          variables: {
            id: this.orderId,
          },
        })
        .then((data) => {
          document.getElementById("load-spinner").style.display = "none";
          document.getElementById("main-div").style.display = "block";
          this.order = data.data.workshopOrder;
          this.labourHours = data.data.workshopOrder.labourHours;
          this.labourPrice = parseFloat(data.data.workshopOrder.labourPrice.amount).toFixed(2);
          this.ratePerHour = parseFloat(data.data.workshopOrder.labourRatePerHour.amount).toFixed(2);
          this.workshopExpectedCompletionDays = data.data.workshopOrder.workshopExpectedCompletionDays;
          this.partQuotations = data.data.workshopOrder.partQuotations.filter(
            (pq) => {
              if (pq.status != "EXPIRED") {
                return pq;
              }
            }
          );
          this.approvedQuotationName = /[^/]*$/.exec(
            data.data.workshopOrder.approvedQuotation
          )[0];
        });
    },
    handleUploadQuotation() {
      this.showUploadQuotationModal = true;
      this.files = [];
      this.titles = [];
    },
    handleFileUpload({ target }) {
      for (var i = 0; i < target.files.length; i++) {
        this.files.push(target.files[i]);
        this.titles.push(target.files[i].name);
      }
    },
    removeImage(index) {
      this.files.splice(index, 1);
      this.titles.splice(index, 1);
    },
    uploadQuotationFiles() {
      document.getElementById("main-div").style.display = "none";
      document.getElementById("load-spinner").style.display = "flex";
      document.getElementById("saveBtn").disabled = true;
      this.$apollo
        .mutate({
          mutation: WORKSHOP_ORDER_ATTACHMENT_UPLOAD,
          variables: {
            id: this.orderId,
            files: this.files,
            titles: this.titles,
          },
        })
        .then((data) => {
          document.getElementById("main-div").style.display = "block";
          document.getElementById("load-spinner").style.display = "none";
          if (data.data.workshopOrderAttachmentUpload.errors.length == 0) {
            this.showUploadQuotationModal = false;
            this.showToast("File(s) uploaded successfully", "success");
            this.files = [];
            this.titles = [];
            document.getElementById("saveBtn").disabled = false;
            document.getElementById("uploadQuotation").value = "";
            this.getOrderDetails();
          } else {
            document.getElementById("saveBtn").disabled = false;
            this.errors = data.data.workshopOrderAttachmentUpload.errors;
            this.errors.map((e) => {
              this.showToast(e.message, "danger");
            });
          }
        });
    },
    editPartQuotation(id) {
      this.pq_id = id;
      this.is_edit = true;
      this.deletePartQuotation();
      this.showPartQuotationModal = true;
      this.total_amount = 0;
      this.inputs = [];
      this.partQuotations.filter((pq) =>  {
        if (pq.id == id) {
          let unit_cost = parseFloat(
            parseFloat(pq.unitPriceSupplier.amount) /
              parseFloat(1 + this.margin_pct / 100)
          ).toFixed(2);
          this.inputs.push({
            partName: pq.partName,
            margin: this.margin_pct,
            unitCost: unit_cost,
            unitPrice: pq.unitPriceSupplier.amount,
            quantity: pq.quantity,
            quality: pq.quality,
            availability: -1,
            brand: pq.brand,
            partNumber: pq.partNumber,
            price: pq.priceSupplier.amount,
          });
        }
      });
      this.inputs.map((input) => {
        this.total_amount = parseFloat(
          parseFloat(this.total_amount) + parseFloat(input.price)
        ).toFixed(2);
      });
    },
    handleComment() {
      this.comment = null;
      this.commentModalShow = true;
    },
    commentUpdate() {
      if (this.comment == null || this.comment == "") {
        this.showToast("Comment is required", "danger");
      } else {
        this.commentModalShow = false;
        document.getElementById("main-div").style.display = "none";
        document.getElementById("load-spinner").style.display = "flex";
        this.$apollo
          .mutate({
            mutation: WORKSHOP_ORDER_UPDATE,
            variables: {
              id: this.order.number,
              comment: this.comment,
            },
          })
          .then((data) => {
            document.getElementById("main-div").style.display = "block";
            document.getElementById("load-spinner").style.display = "none";
            this.isWhisper = false;
            if (data.data.workshopOrderUpdate.errors.length == 0) {
              this.showToast("Comment added successfully", "success");
            } else {
              this.errors = data.data.workshopOrderUpdate.errors;
              this.errors.map((e) => {
                this.showToast(`${e.message}`, "danger");
              });
            }
            //this.getOrderDetails();
            this.order.comments = {};
            this.order.comments = data.data.workshopOrderUpdate.order.comments;
          });
      }
    },

    handleTrackingPdf() {
      let url = this.order.workshopTrackingPdfUrl;
      window.open(url, "_blank");
    },

    copyIdToClipboard(id) {
      // Create a temporary input element
      var input = document.createElement("textarea");
      input.value = id.trim(); // Remove leading/trailing spaces
      document.body.appendChild(input);

      // Select the text within the input
      input.select();
      input.setSelectionRange(0, 99999); // For mobile devices

      // Copy the selected text to the clipboard
      document.execCommand("copy");

      // Clean up by removing the input element
      document.body.removeChild(input);

      var message = "Copied";
      this.showToast("Order Number : " + id + " " + `${message}`, "success");
    },

    copyStaffNoteToClipboard(id) {
      var input_temp = document.createElement("textarea");
      input_temp.setAttribute("class", "text-area-clipboard");
      input_temp.innerHTML = id;
      document.getElementById("notes").appendChild(input_temp);
      input_temp.select();
      input_temp.setSelectionRange(0, 99999);
      document.execCommand("copy");

      var message = "Copied";
      this.showToast("Staff Note : " + id + " " + `${message}`, "success");
    },

    copyValetNoteToClipboard(id) {
      var input_temp = document.createElement("textarea");
      input_temp.setAttribute("class", "text-area-clipboard");
      input_temp.innerHTML = id;
      document.getElementById("notes").appendChild(input_temp);
      input_temp.select();
      input_temp.setSelectionRange(0, 99999);
      document.execCommand("copy");

      var message = "Copied";
      this.showToast("Valet Note : " + id + " " + `${message}`, "success");
    },
  },
};
</script>

<style>
.custom-input {
  display: block;
  width: 100%;
  padding: 0.375em 0.75em;
  font-size: 1em;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25em;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-sizing: border-box;
}
.multiselect-width{
  width: 160px !important;
}

.custom-input-resize {
  display: block; 
  text-align: right;
  width: 110px !important;
  padding: 0.375em 0.75em;
  font-size: 1em;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25em;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-sizing: border-box;
}

.custom-input-labour{
  display: block; 
  text-align: left;
  width: 140px !important;
  padding: 0.375em 0.75em;
  font-size: 1em;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25em;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-sizing: border-box;
}

.table-overflow {
  padding: 10px;
  overflow: visible;
}
.h-display {
  display: flex;
}
.margin-label {
  margin-top: 15px;
  margin-right: 15px;
}
.total-label {
  padding: 15px;
}
.total-display {
  padding-top: 15px;
  padding-left: 10px;
}

.btn-transistion {
  transition: all 0.3s ease 0s;
}
.btn-transistion:hover {
  transform: translateY(3px);
}
.wrap {
  /* height: 100%; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.navigation-link-text {
  color: blue;
  font-weight: bold;
  cursor: pointer;
  text-decoration: underline;
}
.job-info-svg {
  cursor: pointer;
  margin-bottom: 0.1rem;
}
.job-info-svg:hover {
  fill: blue;
}
.cancel-order:hover {
  background-color: red;
  border-color: red;
}
.sumbit-order:hover {
  background-color: green;
  border-color: green;
}
.transition-row {
  transition: transform 0.1s;
}
.transition-row:hover {
  background-color: rgb(245, 245, 245);
  transform: scale(0.99);
}
.toast-style {
  font-size: 1.2rem;
  padding: 1rem;
}
.commentScroll {
  display: block;
  flex-wrap: wrap;
  max-width: 100%;
  max-height: 200px;
  overflow-y: scroll;
  padding: 10px;
}
.text-area-clipboard {
  border: none;
  color: white;
  height: 0;
  width: 0;
}

#tab-content .seconds-span {
  position: absolute;
  right: 100px;
  top: 20px;
}

#tab-content .daysCounter {
  /* position: absolute;
    right: 55%;
    top: 20px; */

  position: relative;
  left: 30%;
  bottom: 40px;
}

#tab-content table tr {
  border: hidden;
}

#tab-content .clock-div-vl {
  border-left: 5px solid green;
  height: 30px;
  transform: translate(38%);
}

#tab-content .tick-div-vl {
  border-left: 5px solid green;
  height: 70px;
  transform: translate(38%);
}

#tab-content tr {
  font-size: 14px;
}

#payment-id {
  display: none;
}

.text-monospace {
  font-family: monospace, monospace !important;
  text-align: right;
}

.scheduled-at .custom-input {
  width: 100%;
}

.scheduled-at .vdatetime-popup {
  width: 280px;
}

#modal-scheduled .modal-dialog {
  max-width: 300px;
}

#modal-labour-details .modal-dialog {
  max-width: 25%;
}

#load-spinner {
  justify-content: center;
  display: none;
}

/* pre{
  display: block;
  font-size: 80.5%;
  color: #212529;
} */

.pre {
  padding: 2px 0px;
  font-size: 15px;
}

.is-paid {
  margin: 0px 10px;
  border: 2px solid #099909;
  border-radius: 4px;
  font-weight: 600;
  font-size: 13px;
  padding: 5px;
  background: #099909;
  color: white !important;
}

.is-unpaid {
  margin: 0px 10px;
  border: 2px solid #f42b3d;
  border-radius: 4px;
  font-weight: 600;
  font-size: 13px;
  padding: 5px;
  background: #f42b3d;
  color: white !important;
}

.is-auth {
  margin: 0px 5px;
  border: 2px solid #e2ac20;
  border-radius: 4px;
  font-weight: 600;
  font-size: 13px;
  padding: 4px;
  background: #e2ac20;
  color: white !important;
}

.is-unauth {
  margin: 0px 5px;
  border: 2px solid #f42b3d;
  border-radius: 4px;
  font-weight: 600;
  font-size: 13px;
  padding: 4px;
  background: #f42b3d;
  color: white !important;
}
.inputWrapper {
  overflow: hidden;
  position: relative;
  cursor: pointer;
}
.fileInput {
  cursor: pointer;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  /*This makes the button huge so that it can be clicked on*/
  font-size: 50px;
}
.hidden {
  /*Opacity settings for all browsers*/
  opacity: 0;
  -moz-opacity: 0;
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
}
.divScroll {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  max-height: 200px;
  overflow-y: scroll;
}
.card-body {
  padding: 0px 0px 15px;
}
.img-tag {
  padding: 5px;
  margin: 3px;
  background-color: #80808038;
  border-radius: 15px;
}

.img-fluid {
  max-width: 100%;
  height: 80px;
}

.notes, .comments {
  overflow-y: auto;
  white-space: pre-wrap;
  width: 100%;
}

.notes {
  max-height: 60px;
}

#modal-scheduled-edit .modal-dialog {
  max-width: 30%;
}

#modal-fully-quoted .modal-dialog{
  max-width: 20%;
}

@media (min-width: 576px) {
}

.modal-dialog {
  max-width: 1600px;
  margin: 1.75rem auto;
}

#modal-comment .modal-dialog {
  max-width: 500px;
}

@media only screen and (max-width: 450px) {
  #tab-content .table th,
  .table td {
    padding: 10px 5px;
  }

  #tab-content tr {
    font-size: 12px;
  }

  #tab-content .daysCounter {
    position: relative;
    left: 7%;
    bottom: 47px;
  }

  #modal-scheduled-edit .modal-dialog {
    max-width: 100%;
  }
}
@media only screen and (max-width: 1500px) {  
  #modal-labour-details .modal-dialog {
    max-width: 25%;
  }
}

@media only screen and (max-width: 1239px) {  
  .custom-input-resize {
    width: 85px !important;
  }
  #modal-labour-details .modal-dialog {
    max-width: 30%;
  }  
  .custom-label{
    font-size: 14px;
    width: 100% !important;
    padding-bottom: 5px;
  }
  .multiselect{
    width: 110px !important;
  }
}

@media only screen and (max-width: 599px) {
  .table-overflow {
    padding: 10px;
    max-height: 200px;
    overflow: scroll;
  }
  .custom-input {
    width: 120px;
    font-size: 14px;
  }
  .h-display {
    display: block;
  }
  .margin-label {
    margin-top: 5px;
    margin-right: 10px;
  }
  .total-label {
    padding: 5px;
  }
  .total-display {
    padding-top: 5px;
    padding-left: 5px;
  }
}
</style>